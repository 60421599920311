import React, { useEffect, useState, Fragment } from "react";
import { Auth, Hub } from 'aws-amplify';
import { Unity, useUnityContext } from "react-unity-webgl";
import './App.css';
import mainLogo from './JetQuest.png';



function App() {  
  const {unityProvider, isLoaded, loadingProgression, requestFullscreen, sendMessage  } = 
  useUnityContext({    
    loaderUrl: "/build/WebGL.loader.js",
    dataUrl: "/build/WebGL.data",
    frameworkUrl: "/build/WebGL.framework.js",
    codeUrl: "/build/WebGL.wasm",
    streamingAssetsUrl: "/streamingassets",
  });  

  const [devicePixelRatio] = useState(window.devicePixelRatio);
  const [user, setUser] = useState(null);
  const loadingPercentage = Math.round(loadingProgression * 100);

  useEffect(() => {
    if (isLoaded) {
      console.log('Unity load complete');
      setTimeout(() => {
        console.log('Try sending data')
        // sendUserData();
        getAuthToken();
      }, 4000)
    }
  });

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          {
            getUser().then(userData => setUser(userData));
          }
          break;
        case 'signOut':
          {
            setUser(null);
          }
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          {
            console.log('Sign in failure', data);
          }
          break;
      }
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }


  function sendUserData(js) {
    sendMessage("SSOUserController", "SsoLoginWithUsernameToken", js);
  }

  function getAuthToken()
  {
    Auth.currentSession().then(res=>{
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()
      let userName = (user.getUsername()).toString();
          
      console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
      console.log(`myJwt: ${jwt}`)
      console.log(`user: ${userName.substring(9)}`)

      let sendjson = {
        username : userName.substring(9),
        token : jwt
      }

      let sendStr = JSON.stringify(sendjson);

      sendUserData(sendStr)
    })
  }


  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }


  return(
    <div className="screenContainer">
    {
      user ? 
      (
        <div className="container">
        {isLoaded === false && (
          <div className="loading-overlay">
            <span class="loader"></span>
            <h3></h3>
            <h3 className="colorOrange">({loadingPercentage}%)</h3>
            <h3>It can take a while at 90 percent, sorry for inconvenience</h3>
            </div>
            )}
            <Fragment>
            <Unity 
              unityProvider={unityProvider}
              style={{ width: 1000, height: 600 }}
              devicePixelRatio={devicePixelRatio}/>
              
            <button onClick={handleClickEnterFullscreen}>Enter Fullscreen</button> 
            </Fragment>

      </div>
      ):
      (
        <div>
        <div id="login-logo">
          <img title="Playbook" src="https://i.ibb.co/5MRH6Rn/JetQuest.png" width="160" height="50" alt="logo" />
        </div>
        <div id="login-content">
          <div id="login-title">
            <h1>Identify yourself before continuing</h1>
          </div>
          <button onClick={() => Auth.federatedSignIn({ provider: 'JetQuest' })}>
            Log In using Office365
          </button>
        </div>
      </div>
      )
    }
    </div>
  )
  
  }

export default App;
